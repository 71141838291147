import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import './style.css'
import { Helmet } from 'react-helmet';
import md5 from 'md5';
import axios from 'axios';
import ImageGallery from './Home/ImageGallery';

const englishData = {
    section1: {
        title: 'Gallery'
    }
}

const gujratData = {
    section1: {
        title: 'ગેલેરી'
    }
}

const Gallery = () => {
    const { language } = useLanguage();
    const [galleryData, setGalleryData] = useState()
    const [images, setimages] = useState({ img: '', title: '' })
    const [loading , setLoading] = useState(true)

    const [data, setData] = useState()
    const [galleryImages, setGalleryImages] = useState()
    const [imgFilter, setImgFilter] = useState()

    useEffect(() => {
        if (language) {
            setGalleryData(englishData)
        } else {
            setGalleryData(gujratData)
        }
    }, [language])


    const date = new Date()
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}${month}${day}`;
    }


    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)

    useEffect(() => {
        axios.post(`https://cms.tejashpatel.in/api/galleryDetail`, { lang: language ? "en" : "gu" }, { headers: { 'X-Custom-Token': token } })
            .then((res) => {
                const reversedData = res?.data?.data?.reverse();
                setData(reversedData);
                setLoading(false)
            })
            .catch((err) => {

            })
    }, [language, token])

    useEffect(() => {
        let imgData = data?.filter((el) => el?.title == imgFilter)

        setGalleryImages(imgData?.[0]?.images)
    }, [imgFilter])

    useEffect(() => {
        setGalleryImages(data?.[0]?.images)
    }, [data])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            {loading &&
            <div class='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100vh', position: 'fixed', top: "0", left: '0', background: 'rgba(255,255,255,0.7)', zIndex: '2' }}>
                <div class="spinner-border" style={{color:'#ea5136'}} role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            <div class='blueBg' >
                <div>
                    <p class='display-1 fw-bold experienceText text-white' data-aos="zoom-in" dangerouslySetInnerHTML={{ __html: galleryData?.section1?.title }}></p>
                </div>
            </div>

            <div class=' col-md-11 m-auto d-flex align-items-center gap-4' style={{ overflowX: 'auto' }}>
                {
                    data?.map((el) => {
                        return (
                            <button class="custom-btn btnBlue text-nowrap" onClick={() => setImgFilter(el?.title)}><span>{el?.title}</span></button>
                        )
                    })
                }
            </div>

            <div class='mt-4'>
                <ImageGallery imgData={galleryImages} />
            </div>

            {/* <div>
                <div class='gallery row  m-0  p-0'>
                    <div class='col-lg-6 row p-0 m-0'>
                        <div class='col-sm-6 p-0'>
                            <div class='galImg' style={{ height: '200px' }}>
                                <img src={require('../images/g1.jpg')} onClick={() => setimages({ img: require('../images/gg1.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                            <div class='row p-0 m-0' style={{ height: '200px' }}>
                                <div class='col-6 galImg'>
                                    <img src={require('../images/g2.jpg')} onClick={() => setimages({ img: require('../images/gg2.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '200px' }} alt="" />
                                </div>
                                <div class='col-6 m-0 p-0 galImg '>
                                    <img src={require('../images/g3.jpg')} onClick={() => setimages({ img: require('../images/gg3.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '200px' }} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class='col-sm-6 galImg p-0 m-0' style={{ height: '400px' }}>
                            <img src={require('../images/g4.jpg')} onClick={() => setimages({ img: require('../images/gg4.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                        </div>
                    </div>
                    <div class='col-lg-6 row p-0 m-0 '>
                        <div class='col-md-6 p-0 m-0'>
                            <div class='row p-0 m-0'>
                                <div class='col-6 p-0 m-0 galImg' style={{ height: '150px' }}>
                                    <img src={require('../images/g5.jpg')} onClick={() => setimages({ img: require('../images/gg5.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                                <div class='col-6 p-0 m-0 galImg' style={{ height: '150px' }}>
                                    <img src={require('../images/g6.jpg')} onClick={() => setimages({ img: require('../images/gg6.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                            </div>
                            <div class='row p-0 m-0'>
                                <div class='col-7 m-0 p-0 galImg' style={{ height: '250px' }}>
                                    <img src={require('../images/g7.jpg')} onClick={() => setimages({ img: require('../images/gg7.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                                <div class='col-5 p-0 m-0 galImg' style={{ height: '250px' }}>
                                    <img src={require('../images/g8.jpg')} onClick={() => setimages({ img: require('../images/gg8.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class='col-md-6 p-0 m-0'>
                            <div class='galImg' style={{ height: '250px' }}>
                                <img src={require('../images/g9.jpg')} onClick={() => setimages({ img: require('../images/gg9.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                            <div class='galImg' style={{ height: '150px' }}>
                                <img src={require('../images/g10.jpg')} onClick={() => setimages({ img: require('../images/gg10.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class='gallery row  m-0  p-0'>
                    <div class='col-lg-6 row p-0 m-0'>
                        <div class='col-sm-6 p-0 m-0 row bg-success '>
                            <div class='galImg col-6' style={{ height: '400px' }}>
                                <img src={require('../images/g2.jpg')} onClick={() => setimages({ img: require('../images/gg2.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                            <div class='row col-6 p-0 m-0' style={{ height: '200px' }}>
                                <div class='galImg'>
                                    <img src={require('../images/g1.jpg')} onClick={() => setimages({ img: require('../images/gg1.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '200px' }} alt="" />
                                </div>
                                <div class=' galImg '>
                                    <img src={require('../images/g3.jpg')} onClick={() => setimages({ img: require('../images/gg3.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '200px' }} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class='col-md-6 p-0 m-0'>
                            <div class='galImg' style={{ height: '250px' }}>
                                <img src={require('../images/g10.jpg')}onClick={() => setimages({ img: require('../images/gg10.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                            <div class='galImg' style={{ height: '150px' }}>
                                <img src={require('../images/g6.jpg')} onClick={() => setimages({ img: require('../images/gg6.jpg'), title: 'Image Title'})}style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                        </div>
                    </div>

                    <div class='col-lg-6 row p-0 m-0 '>
                        <div class='col-md-6 p-0'>
                            <div class='galImg' style={{ height: '400px' }}>
                                <img src={require('../images/g5.jpg')}  onClick={() => setimages({ img: require('../images/gg5.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                        </div>
                        <div class='col-md-6 p-0'>
                            <div class='galImg' style={{ height: '400px' }}>
                                <img src={require('../images/g9.jpg')} onClick={() => setimages({ img: require('../images/gg9.jpg'), title: 'Image Title'})} style={{ width: '100%', height: '100%' }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* {
                images?.img &&
                    <div class='galleryPopUp'>
                        <div class='col-11 m-auto' >
                            <div class='d-flex align-items-center justify-content-end'>
                                <i class="bi bi-x-circle text-white fs-1" style={{ cursor: 'pointer' }} onClick={() => setimages('')}></i>
                            </div>
                            <div class='m-auto col-lg-4 col-md-6 col-sm-8 ' >
                                <img src={images?.img} style={{ width: '100%', aspectRatio: '1/1' }} alt="" />
                            </div>
                            <div class='mt-3'>
                                <p class='fw-bold fs-5 text-white'>{images?.title}</p>
                            </div>

                        </div>

                    </div>  
            } */}
        </div>
    )
}

export default Gallery