import React, { useEffect, useState } from 'react'
import { useLanguage } from './LanguageContext';
import './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
const englishData = {
    section1: {
        title1: 'Agricultural',
        title2: 'Visionary',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt uLorem i'
    },
    section2: {
        text: 'Lorem ipsum dolor sit amet, constetur adipisicing elit, sed do eiusmod teor incididunt uLorem ipsum dolor sitmet, consectetur adipisicing elit',
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'Goals',
            points: ["lorem ipsum Dolor Sit", "amet, consectetur ", "adipisicing elit, sed do ", "eiusmod tempor"]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Lorem ipsum doLorem iLorem ipsum dolor psum dolor lor ',
            text: 'mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r '
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Lorem ipsum doLorem iLorem ipsum dolor psum dolor lor ',
            text: 'mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r '
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        // imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'Strong Purpose',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'Respect for Diversity',
        point2Img: `${require('../images/proIcon3.png')}`,
        point3: 'Unity & Teamwork',
        point3Img: `${require('../images/proIcon2.png')}`,
        point4: 'Open Communication',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'Employee centric',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'Flexible Work Environment',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'Impact and Benefits',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '4 Regional Offices',
                text: 'AP,  Gujrat, Chhattisgarh, Telangana'
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: '200+ Employees',
                text: 'Administrative, Production, Field promoters etc'
            },
            {
                img: `${require('../images/benefit3.png')}`,
                title: '4 C&F’s',
                text: '(Carry and Forward) Units'
            },
            {
                img: `${require('../images/benefit4.png')}`,
                title: '200+ Distributors',
                text: 'Distributors across India'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '400+ Dealers',
                text: 'Lorem Ipsum sit'
            },
            {
                img: `${require('../images/benefit6.png')}`,
                title: '1 million +',
                text: 'Happy Farmers'
            },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'Get Involved',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod t',
        button1: 'Donate',
        button2: 'Volunteer'
    }
}


const gujratData = {
    section1: {
        title1: 'Agricultural',
        title2: 'Visionary',
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt uLorem i'
    },
    section2: {
        text: 'Lorem ipsum dolor sit amet, constetur adipisicing elit, sed do eiusmod teor incididunt uLorem ipsum dolor sitmet, consectetur adipisicing elit',
        timeLine: {
            title: 'Timeline',
            weeks: '20 Weeks'
        },
        goals: {
            title: 'Goals',
            points: ["lorem ipsum Dolor Sit", "amet, consectetur ", "adipisicing elit, sed do ", "eiusmod tempor"]
        }
    },
    section3: [
        {
            background: '#EA5136',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Lorem ipsum doLorem iLorem ipsum dolor psum dolor lor ',
            text: 'mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r '
        },
        {
            background: '#727CB6',
            icon: `${require('../images/aboutIcon.png')}`,
            title: 'Lorem ipsum doLorem iLorem ipsum dolor psum dolor lor ',
            text: 'mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r '
        }
    ],
    section4: {
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. mpor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolompor incididunt uLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidiipsum dolor r ',
        // imgbg: `${require('../images/project2.jpg')}`,
    },

    section5: {
        point1: 'Strong Purpose',
        point1Img: `${require('../images/proIcon1.png')}`,
        point2: 'Unity & Teamwork',
        point2Img: `${require('../images/proIcon2.png')}`,
        point3: 'Respect for Diversity',
        point3Img: `${require('../images/proIcon3.png')}`,
        point4: 'Open Communication',
        point4Img: `${require('../images/proIcon4.png')}`,
        point5: 'Employee centric',
        point5Img: `${require('../images/proIcon5.png')}`,
        point6: 'Flexible Work Environment',
        point6Img: `${require('../images/proIcon6.png')}`,
    },
    section6: {
        title: 'Impact and Benefits',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor.',
        cart: [
            {
                img: `${require('../images/benefit1.png')}`,
                title: '4 Regional Offices',
                text: 'AP,  Gujrat, Chhattisgarh, Telangana'
            },
            {
                img: `${require('../images/benefit2.png')}`,
                title: '200+ Employees',
                text: 'Administrative, Production, Field promoters etc'
            },
            {
                img: `${require('../images/benefit3.png')}`,
                title: '4 C&F’s',
                text: '(Carry and Forward) Units'
            },
            {
                img: `${require('../images/benefit4.png')}`,
                title: '200+ Distributors',
                text: 'Distributors across India'
            },
            {
                img: `${require('../images/benefit5.png')}`,
                title: '400+ Dealers',
                text: 'Lorem Ipsum sit'
            },
            {
                img: `${require('../images/benefit6.png')}`,
                title: '1 million +',
                text: 'Happy Farmers'
            },
        ]
    },
    section7: {
        img: `${require('../images/project3.jpg')}`,
        title: 'Get Involved',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod t',
        button1: 'Donate',
        button2: 'Volunteer'
    }
}

const ProjectDetails = () => {
    const { language } = useLanguage();

    const [projectDetailData, setProjectDetailData] = useState()

    useEffect(() => {
        AOS.init({ duration: 1000 });
    })

    useEffect(() => {
        if (language) {
            setProjectDetailData(englishData)
        } else {
            setProjectDetailData(gujratData)
        }
    }, [language])


    return (
        <div style={{ overflowX: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div class='projectDetailTop' >
                <div class='col-lg-4 col-md-6 col-10 ' style={{ position: 'absolute', bottom: '5%', overflow: 'hidden' }}>
                    <div class='col-12' style={{ background: '#EA5136' }} data-aos="fade-right">
                        <p class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title1}</p>
                    </div>
                    <p data-aos="fade-right" class='fs-1 text-white' style={{ width: 'fit-content', marginLeft: '10%' }} >{projectDetailData?.section1?.title2}</p>

                    <div class='col-10 text-start mt-3 text-white' style={{ marginLeft: '10%' }} data-aos="fade-right">
                        <p class='fs-5'>{projectDetailData?.section1?.text}</p>
                    </div>
                </div>
            </div>

            <div class='col-11 row text-start m-auto my-5'>
                <div class='col-md-7 mt-3'>
                    <div class='col-lg-8' data-aos="fade-right">
                        <p class='fs-3'>{projectDetailData?.section2?.text}</p>
                    </div>
                </div>
                <div class='col-md-5'>
                    <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-calendar2-fill mx-1"></i></span> <span>{projectDetailData?.section2?.timeLine?.title}</span></p>
                        <p class='mx-4'>{projectDetailData?.section2?.timeLine?.weeks}</p>
                    </div>
                    <div class='mt-3' data-aos="fade-up">
                        <p style={{ color: '#EA5136' }} class='fw-bold'><span><i class="bi bi-rocket-takeoff-fill mx-1"></i></span> <span>{projectDetailData?.section2?.goals?.title}</span></p>
                        <ul>
                            {
                                projectDetailData?.section2?.goals?.points?.map((el) => {
                                    return (

                                        <li class=''>{el}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>


            </div>

            <div class='row'>
                {
                    projectDetailData?.section3?.map((el) => {
                        return (
                            <div class='col-md-6 text-start' data-aos="fade-right" style={{ background: `${el.background}` }}>
                                <div class='col-11 m-auto text-white'>
                                    <div class='my-4'>
                                        <img src={el.icon} style={{ width: '36px', height: '36px' }} alt="" />
                                    </div>
                                    <p class='fs-4 fw-semibold my-4'>{el.title}</p>
                                    <p class=''>{el.text}</p>
                                    <br />
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            <div class='col-lg-10 col-11 m-auto my-5' data-aos="zoom-in">
                <p class='tJustify'>{projectDetailData?.section4?.text}</p>
            </div>

            <div class='row justify-content-end m-0'>
                <div class='col-md-11 rounded-5 row justify-content-end p-0' style={{ backgroundImage: `url(${projectDetailData?.section4?.imgbg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                    <div class='col-md-6 col-sm-8 col-11 text-end d-flex justify-content-end text-black' data-aos="fade-left" style={{ background: '#FBF7A9', clipPath: 'inset(0 0 0 0 round 150px 0 0 150px)' }}>
                        <div class='my-5 col-11 p-2'>
                            <p class='fs-3 fw-bold text-black'>{projectDetailData?.section4?.title}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class='benifitsUp'>

                <div class='benifitsUpPoint1' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point1Img} style={{ width: '120px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point1}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint2' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point2Img} style={{ width: '35px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point2}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint3' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point3Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point3}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint4' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point4Img} style={{ width: '60px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point4}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint5' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point5Img} style={{ width: '20px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point5}</p>
                    </div>
                </div>
                <div class='benifitsUpPoint6' data-aos="zoom-in">
                    <div class=''>
                        <img src={projectDetailData?.section5?.point6Img} style={{ width: '100px' }} alt="" />
                        <p class='mt-3'>{projectDetailData?.section5?.point6}</p>
                    </div>
                </div>
            </div>
            <br />

            <div style={{ background: '#727CB6', color: 'white' }}>
                <br />
                <p class='h1 my-3' data-aos="fade-down">{projectDetailData?.section6?.title}</p>
                <p class='col-lg-4 col-md-6 col-11 m-auto' data-aos="fade-down">{projectDetailData?.section6?.text}</p>
                <div class='col-11 row justify-content-center m-auto my-4'>
                    {
                        projectDetailData?.section6?.cart?.map((el) => {
                            return (
                                <div class='col-lg-4 col-md-6 my-3' data-aos="fade-up">
                                    <div class='col-11 m-auto rounded-4  h-100 p-2' style={{ backgroundImage: 'linear-gradient( rgba(65,72,116,0.9), rgba(65,72,116,0.1))' }}>
                                        <img class='mt-3' src={el.img} style={{ width: '60px' }} alt="" />
                                        <p class='fs-5 fw-bold my-3'>{el.title}</p>
                                        <p class='my-3'>{el.text}</p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <br />

            </div>

            <div class=' row flex-column-reverse flex-md-row '>
                <div class='col-md-6'>
                    <div class='col-sm-10 col-11 m-auto text-start my-4'>
                        <p class='fs-1 fw-bold' data-aos="fade-right" style={{ color: '#EA5136' }}>{projectDetailData?.section7?.title}</p>
                        <p class='my-2 fs-5' data-aos="fade-right">{projectDetailData?.section7?.text}</p>
                        <div class='mt-3' data-aos="fade-right">
                            <button class="custom-btn btnBlue "><span>{projectDetailData?.section7?.button1}</span></button>
                            <button class="custom-btn btnOrange"><span>{projectDetailData?.section7?.button2}</span></button>
                        </div>
                    </div>
                </div>
                <div class='col-md-6' data-aos="zoom-in">
                    <img src={projectDetailData?.section7?.img} style={{ width: '100%', minHeight: '300px' }} alt="" />
                </div>
            </div>


        </div>
    )
}

export default ProjectDetails